import React from 'react'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import BlockContent from '@sanity/block-content-to-react'

import { Layout } from '../components'
import { Seo } from '../components/base'
import { Button } from '../components/shared/Button'

const NotFoundPage = ({ data, location, pageContext }) => {
  const languages = { pageContext }
  const { title, _rawText, cta, image } = data.allSanityFourOhfourPage.nodes[0]
  const bgImage = getImage(image?.image?.asset)
  return (
    <Layout location={location} title={title} translations={languages}>
      <Seo title={title} description={title} />
      <BgImage className="w-full px-10 py-16 md:h-330" image={bgImage}>
        <div className="flex flex-col justify-center items-center md:h-140 text-center text-white">
          <BlockContent
            blocks={_rawText}
            renderContainerOnSingleChild
            className="mb-16 md:w-3/5 headline-text-shadow uppercase"
          />
          <Button path="/" label={cta} />
        </div>
      </BgImage>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query ($language: String!) {
    allSanityFourOhfourPage(
      filter: {
        i18n_lang: { eq: $language }
        isNotAvailableInCountry: { ne: true }
      }
    ) {
      nodes {
        i18n_lang
        image {
          ...ImageObjectQuery
        }
        _rawText
        cta
        subtitle
      }
    }
  }
`
